import type { PriceClasses } from 'fukku/Price/types'
import { getShouldShowExtraInfo } from 'fukku/Price/utils/getShouldShowExtraInfo/getShouldShowExtraInfo'
import type { Channel } from 'types/masterData'
import { applyStylesIf, cx } from 'utils/cx'

import { Discount } from '../ExtraInfo/Content/Discount'
import { ExtraInfo } from '../ExtraInfo/ExtraInfo'

import styles from './DiscountRate.module.scss'

type DiscountRateProps = {
	align?: 'start' | 'center' | 'end'
	channel: Channel
	classes: PriceClasses
	crossedOut: number[]
	discountRate?: number
	discountText?: string
	hasPriceHistory: boolean
	limitDiscount?: number
	showDiscountInfo: boolean
	price: number
	showDiscount: boolean
	hideSaleOrPromoPrice: boolean
	horizontal: boolean
}

export function DiscountRate({
	align = 'start',
	channel,
	classes,
	crossedOut,
	discountRate = 0,
	discountText,
	hasPriceHistory,
	limitDiscount,
	showDiscountInfo,
	price,
	showDiscount,
	hideSaleOrPromoPrice,
	horizontal,
}: DiscountRateProps) {
	const shouldShowDiscount = showDiscount && Boolean(discountText)

	const showExtraInfo = hideSaleOrPromoPrice || showDiscountInfo

	const isHightlighted = Number(limitDiscount) < discountRate
	const pricesHistory = [...crossedOut, price]

	const shouldShowExtraInfo = getShouldShowExtraInfo({
		channel,
		crossedOut,
		hasPriceHistory,
		showExtraInfo,
		shouldShowDiscount,
	})

	if (!shouldShowDiscount && !shouldShowExtraInfo) {
		return null
	}

	return (
		<div
			className={cx(
				styles.discountRate,
				styles[align],
				isHightlighted && shouldShowDiscount
					? styles.hightlightedDiscount
					: classes.discountRateWrapper,
				applyStylesIf(shouldShowExtraInfo, styles.discountRateExtraInfo),
				applyStylesIf(!shouldShowDiscount, styles.discountRateWithoutDiscount)
			)}
		>
			{shouldShowDiscount && <Discount discountText={discountText} />}
			{shouldShowExtraInfo && (
				<ExtraInfo
					pricesHistory={pricesHistory}
					shouldShowDiscount={shouldShowDiscount}
					hideSaleOrPromoPrice={hideSaleOrPromoPrice}
					horizontal={horizontal}
					isHightlighted={isHightlighted}
				/>
			)}
		</div>
	)
}
